

























































import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Api } from '@/edshed-common/api'

@Component({
  name: 'D6SchoolsView'
})

export default class D6Schools extends Mixins(ComponentHelper) {
  loading: boolean = false
  schools: any[] | null = []

  async mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.loading = true
      await this.getSchools()
    }
  }

  async getSchools () {
    try {
      const resp = await Api.getD6Schools()
      console.log(resp)
      this.schools = resp.schools
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load schools',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
    this.loading = false
  }

  async activateSchool (d6Id: number) {
    this.loading = true
    await Api.d6ActivateSchool(d6Id)
    this.getSchools()
  }

  async deActivateSchool (d6Id: number) {
    this.loading = true
    await Api.d6DeactivateSchool(d6Id)
    this.getSchools()
  }

  async sync (d6Id: number) {
    await Api.d6Sync(d6Id)
    this.$buefy.toast.open({
      duration: 5000,
      message: 'Data sync initiated',
      position: 'is-bottom',
      type: 'is-success'
    })
  }
}
